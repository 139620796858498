import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import '../js/firebaseWorker/firebaseWorker.js';
import { KatapultJob } from './katapult-job/KatapultJob.js';
import { MLD, useAdminFieldValue } from './MasterLocationDirectory.js';
import { GetKLogicStandardDataset } from './GetKLogicStandardDataset.js';
import { KLogic } from './KLogic.js';
import { Parallel } from './Parallel.js';
import { Convert } from './Convert.js';
import { GeoFire } from 'geofire';
import { PickAnAttribute } from './PickAnAttribute.js';
import { LocationSearch } from './LocationSearch.js';

/** @import { DirectoryData, DirectoryLocationRecord } from './MasterLocationDirectory'; */

let adminFirebase;
/** @type {firebase.database.Database} */
let realtimeDb = globalThis.FirebaseWorker?.database() ?? firebase.database();
/** @type {firebase.firestore.Firestore} */
let firestoreDb = firebase.firestore();

/**
 * Use the Firebase Admin SDK instead of the client SDK
 * @param {{ database: function, firestore: function }} admin - The Firebase Admin SDK
 * @param {object} adminFieldValue - The FieldValue object from the Admin SDK (most reliably attained via
 * `const { FieldValue } = require('firebase-admin').firestore;`)
 */
export function useAdminSDK(admin, adminFieldValue) {
  adminFirebase = admin;
  realtimeDb = admin.database();
  firestoreDb = admin.firestore();
  useAdminFieldValue(adminFieldValue);
}

/**
 * @typedef {object} OverlappingLocation
 * @property {string} searchNodeId - The id of the location in the search job (usually a node id)
 * @property {string|null} overlappingLocationId - The id of the directory location that registers as overlapping
 * @property {string} searchJobId - The id of the job that is being searched for overlapping work
 * @property {string} overlappingJobId - The id of the job that contains the overlapping location
 * @property {string} searchMethod - The method used to find the overlapping location
 * @property {object} details - Additional details about the overlapping location
 * @property {object|null} details.locationData - The data from the overlapping location document in the location directory
 * @property {number} details.distance - The distance between the search location and the overlapping location
 * @property {string} [details.uniqueId] - The unique id of the overlapping location
 * @property {object} [details.attributeLocationData] - The data from the attribute location document in the location directory
 */

/**
 * @typedef {object} OverlappingJobRecord
 * @property {string} id - The id of the job
 * @property {boolean} isShared - Whether the job is shared with the authenticated user
 * @property {OverlappingLocation[]} locations - The locations containing the job
 * @property {string} [name] - The name of the job (if available; undefined if not shared)
 * @property {object} [appInfo] - Information about the job (if available; undefined if not shared)
 * @property {string} [appInfo.appName] - The name of the app
 * @property {string} [appInfo.appNumber] - The number of the app
 * @property {string} [appInfo.appType] - The type of the app
 * @property {string} [appInfo.appStatus] - The status of the app
 * @property {number} [appInfo.dateSubmitted] - The date the app was submitted
 * @property {number} [appInfo.dateInstalled] - The date the app was installed
 * @property {number} [appInfo.dateCanceled] - The date the app was canceled
 */

/**
 * @typedef {object} OverlappingWorkQueryOptions
 * @property {boolean} [useUniqueIds] - Whether to use unique pole ids to determine overlapping work
 * @property {string} [uniqueIdAttribute] - An attribute to get from the node to use as the unique id. The attribute should be a `textbox`
 * type attribute. If not provided, the node's company lookup id will be used. Any nodes without a value for this attribute will be excluded
 * from the search. If multiple values are found, the first one will be used.
 * @property {boolean} [skipLocationChecking] - Skip location checking and only consider overlapping work if the unique ids match
 * @property {number} [overrideLocationCheckRadius] - Override the directory-configured radius for location checks. If not provided and no
 * radius is configured, defaults to 36 feet.
 * @property {boolean} [removeDuplicateLocations] - Remove duplicate locations from the overlapping work records. Defaults to true.
 * Duplicate records are identified when multiple overlapping locations register for the same search node in a single overlapping job.
 * Records whose search method is 'UNIQUE_ID' are preferred over 'LOCATION' records, and records with shorter distances are preferred over
 * longer distances.
 * @property {[string, object][]} [overrideNodeEntriesToCheck] - The nodes of the job to check for overlapping work. If not provided, the
 * nodes will be fetched from the job.
 * @property {boolean} [ignoreCheckInFilter] - Ignore the check-in filter from the location directory (check every node in the job for
 * overlapping work)
 */

/**
 * @typedef {object} OverlappingWorkQueueResult
 * @property {OverlappingJobRecord[]} queue - The overlapping jobs sorted by queue position, including the search job
 * @property {OverlappingJobRecord[]} allOverlappingJobs - All jobs that overlap with the search job
 */

/**
 * Get a list of jobs that overlap with the given search job
 * @param {string} jobId - The id of the job to check for overlapping work
 * @param {DirectoryData} directory - The location directory to check for overlapping work
 * @param {OverlappingWorkQueryOptions} [options] - Options for the overlapping query
 * @returns {Promise<OverlappingWorkQueueResult>} The overlapping jobs and unshared jobs
 */
export async function getOverlappingWorkQueue(jobId, directory, options = {}) {
  // Get the overlapping jobs
  const overlappingJobs = await getOverlappingWork(jobId, directory, options);
  // Get the search job info and add it to the overlapping jobs for inclusion in the queue
  const overlappingJobsAndSearchJob = [...overlappingJobs, await getJobInfo(jobId)];

  return {
    queue: filterAndSortOverlappingJobs(overlappingJobsAndSearchJob),
    allOverlappingJobs: overlappingJobs
  };
}

/**
 * Get a list of jobs that overlap with the given job according to a provided location directory.
 * @param {string} searchJobId - The id of the job to check for overlapping work
 * @param {DirectoryData} directory - The location directory to check for overlapping work
 * @param {OverlappingWorkQueryOptions} [options] - Options for the overlapping query
 * @returns {Promise<OverlappingJobRecord[]>} The overlapping jobs
 */
export async function getOverlappingWork(searchJobId, directory, options = {}) {
  const { _owner_company: ownerCompany, _id: directoryId, checkInFilter } = directory;
  // Validate the directory data
  if (!ownerCompany || !directoryId) throw new Error('Invalid directory data');

  // Extract the options
  const {
    useUniqueIds = false,
    uniqueIdAttribute,
    skipLocationChecking = false,
    overrideLocationCheckRadius,
    removeDuplicateLocations = true,
    overrideNodeEntriesToCheck,
    ignoreCheckInFilter = false
  } = options;

  // Validate the options
  const uniqueIdAttributeIsValid = typeof uniqueIdAttribute == 'string' || uniqueIdAttribute == null;
  if (useUniqueIds && !uniqueIdAttributeIsValid) throw new Error('Unique id attribute must be a string or null when using unique ids');
  if (skipLocationChecking && !useUniqueIds) throw new Error('Unique ids must be used when "skipLocationChecking" is true');
  if (overrideLocationCheckRadius != null && overrideLocationCheckRadius < 0) throw new Error('Override radius must be a positive number');

  // Get the node entries from the job
  const nodeEntriesToCheck = await getNodeEntriesToCheck(searchJobId, { overrideNodeEntriesToCheck, checkInFilter, ignoreCheckInFilter });

  // Get the overlapping location from unique ids and locations (based on the options)
  const overlappingWorkFromUniqueIds = useUniqueIds
    ? await getOverlappingLocationsByUniqueIds(searchJobId, nodeEntriesToCheck, directory, uniqueIdAttribute)
    : [];
  const overlappingWorkFromLocations = !skipLocationChecking
    ? await getOverlappingLocationsByProximity(searchJobId, nodeEntriesToCheck, directory, overrideLocationCheckRadius)
    : [];
  // Combine the overlapping locations from unique ids and locations
  const allOverlappingLocations = [...overlappingWorkFromUniqueIds, ...overlappingWorkFromLocations];

  // Get all unique job ids from the overlapping locations and filter out the search job id
  const allUniqueJobIds = [...new Set(allOverlappingLocations.map((location) => location.overlappingJobId))];
  const idsOfOverlappingJobs = allUniqueJobIds.filter((jobId) => jobId !== searchJobId);

  // Return the overlapping job records
  const overlappingJobRecords = await Promise.all(
    idsOfOverlappingJobs.map(async (jobId) => await getJobInfo(jobId, allOverlappingLocations))
  );
  return removeDuplicateLocations ? removeDuplicateOverlappingRecords(overlappingJobRecords) : overlappingJobRecords;
}

/**
 * Get a list of locations that overlap with the given job's node entries based on unique ids in a location directory.
 * @param {string} searchJobId - The id of the job to check for overlapping work
 * @param {[string, object][]} nodeEntriesToCheck - The nodes of the job to check for overlapping work
 * @param {DirectoryData} directory - The location directory to check for overlapping work
 * @param {string} [uniqueIdAttribute] - An attribute to get from the node to use as the unique id. The attribute should be a `textbox` type
 * attribute. If not provided, the node's company lookup id will be used. Any nodes without a value for this attribute will be excluded from
 * the search. If multiple values are found, the first one will be used.
 * @returns {Promise<OverlappingLocation[]>} The overlapping locations
 */
export async function getOverlappingLocationsByUniqueIds(searchJobId, nodeEntriesToCheck, directory, uniqueIdAttribute) {
  const { _owner_company: ownerCompany, _id: directoryId } = directory;
  if (!ownerCompany || !directoryId) throw new Error('Invalid directory data');

  // Get the unique ids from the node entries
  const unfilteredNodeLocationsWithUniqueIds = nodeEntriesToCheck.map(([searchNodeId, node]) => {
    const untrimmedUniqueId = uniqueIdAttribute ? PickAnAttribute(node.attributes, uniqueIdAttribute) : node.companyLookupId;
    const uniqueId = untrimmedUniqueId?.trim();
    return { searchNodeId, searchNodeLocation: [node.latitude, node.longitude], uniqueId };
  });
  // Filter out nodes without a unique id or whose length is less than 6
  const nodeLocationsWithUniqueIds = unfilteredNodeLocationsWithUniqueIds.filter(
    ({ uniqueId }) => uniqueId != null && uniqueId.length >= 6
  );

  const searchOptions = { directoryId, limit: 0, firebaseReference: adminFirebase ?? firebase };
  const nestedOverlappingLocations = await Parallel.map(
    nodeLocationsWithUniqueIds,
    async ({ searchNodeId, searchNodeLocation, uniqueId }) => {
      // Get the attribute locations that match the unique id
      const attributeLocations = await MLD.searchAttributeLocations(ownerCompany, uniqueId, searchOptions);

      // For each attribute location, get the matching directory location
      const locationEntriesByIndex = await Promise.all(
        attributeLocations.map(
          async (attributeLocation) => await getLocationEntryFromGeohash(attributeLocation.g, ownerCompany, directoryId)
        )
      );

      // For each attribute location, get the return data
      const searchInfo = { searchNodeId, searchNodeLocation, searchJobId, searchMethod: 'UNIQUE_ID' };
      return attributeLocations.map((attributeLocationData, index) =>
        getLocationInfo(searchInfo, {
          overlappingJobId: attributeLocationData.j,
          locationEntry: locationEntriesByIndex[index],
          uniqueId,
          attributeLocationData
        })
      );
    },
    10
  );

  // Flatten the nested overlapping locations and return them
  return nestedOverlappingLocations.flat();
}

/**
 * Get a list of locations that overlap with the given job's node entries based on proximity to locations in a location directory.
 * @param {string} searchJobId - The id of the job to check for overlapping work
 * @param {[string, object][]} nodeEntriesToCheck - The nodes of the job to check for overlapping work
 * @param {DirectoryData} directory - The location directory to check for overlapping work
 * @param {number} [overrideLocationCheckRadius] - Override the directory-configured radius for location checks
 * @returns {Promise<OverlappingLocation[]>} The overlapping locations
 */
export async function getOverlappingLocationsByProximity(searchJobId, nodeEntriesToCheck, directory, overrideLocationCheckRadius) {
  const { _owner_company: ownerCompany, _id: directoryId, radius: configuredRadius } = directory;
  if (!ownerCompany || !directoryId) throw new Error('Invalid directory data');

  // Get the maps API
  const { maps: mapsAPI } = globalThis.google;
  if (mapsAPI == null) throw new Error('Google Maps API must be available in the global scope');

  // Get the search radius in meters
  const searchRadiusInFeet = overrideLocationCheckRadius ?? configuredRadius ?? 36;
  const searchRadiusInMeters = Convert(searchRadiusInFeet, 'ft', 'm');

  // Get all locations within a radius of each node cluster in the job
  const locationsWithinJobRadius = await getLocationsNearNodesInJob(nodeEntriesToCheck, directory, searchRadiusInMeters);

  // For each node in the job, get all locations that are within the search radius of its location
  const locationsEntriesWithinJobRadius = Object.entries(locationsWithinJobRadius);
  const overlappingLocationsForEachNode = nodeEntriesToCheck.map(([nodeId, node]) => {
    const nodeLocation = [node.latitude, node.longitude];
    const overlappingLocations = filterLocationEntriesBySearchRadius(
      locationsEntriesWithinJobRadius,
      new mapsAPI.LatLng({ lat: nodeLocation[0], lng: nodeLocation[1] }),
      searchRadiusInMeters
    );
    return { nodeId, nodeLocation, overlappingLocations };
  });

  // Expand each node's overlapping locations into a list of overlapping locations by job
  const nestedOverlappingLocations = overlappingLocationsForEachNode.map(({ nodeId, nodeLocation, overlappingLocations }) => {
    return overlappingLocations.map(([locationId, location]) =>
      // For each job on the record, return an overlapping location record
      location.d.j.map((overlappingJobId) =>
        getLocationInfo(
          { searchNodeId: nodeId, searchNodeLocation: nodeLocation, searchJobId, searchMethod: 'LOCATION' },
          { overlappingJobId, locationEntry: [locationId, location] }
        )
      )
    );
  });

  // Flatten the nested overlapping locations and return them
  return nestedOverlappingLocations.flat(2);
}

/**
 * Get the nodes of the job to check for overlapping work
 * @param {string} searchJobId - The job id to check for overlapping work
 * @param {object} [options] - Options for getting the node entries
 * @param {[string, object][]} [options.overrideNodeEntriesToCheck] - The nodes of the job to check for overlapping work
 * @param {object} [options.checkInFilter] - The check-in filter from the location directory
 * @param {boolean} [options.ignoreCheckInFilter] - Ignore the check-in filter from the location directory
 * @returns {Promise<[string, object][]>} The node entries to check for overlapping work
 */
async function getNodeEntriesToCheck(searchJobId, options = {}) {
  const { overrideNodeEntriesToCheck, checkInFilter, ignoreCheckInFilter } = options;
  // If the node entries are provided, return them
  if (overrideNodeEntriesToCheck) return overrideNodeEntriesToCheck;

  // Get the nodes of the job
  const job = KatapultJob.fromId(searchJobId, { noCache: true });
  const jobNodes = await job.getNodes();
  // Filter the nodes by the check-in filter if it exists
  return checkInFilter && !ignoreCheckInFilter ? await filterNodesByCheckInFilter(job, jobNodes, checkInFilter) : Object.entries(jobNodes);
}

/**
 * Filter nodes by the check-in filter from the location directory
 * @param {KatapultJob} job - The Katapult job
 * @param {object} nodes - The nodes of the job
 * @param {object} checkInFilter - The check-in filter (from the location directory)
 * @returns {Promise<object[]>} The filtered node entries
 */
async function filterNodesByCheckInFilter(job, nodes, checkInFilter) {
  const jobModel = await job.getModel();
  const modelAttributesRef = realtimeDb.ref(`photoheight/company_space/${jobModel}/models/attributes`);
  const modelAttributesSnapshot = await modelAttributesRef.once('value').catch(() => {
    // Throw a human error if the snapshot couldn't be retrieved
    throw new Error('Failed to get model attributes, does requester have permission?');
  });
  const modelAttributes = modelAttributesSnapshot.val();

  // TODO (2024-07-25): Do the thing with model defaults?

  return Object.entries(nodes).filter(([nodeId]) => {
    const dataset = GetKLogicStandardDataset('nodes', { nodes }, modelAttributes, { nodeId });
    return KLogic.compute(checkInFilter, dataset);
  });
}

/**
 * Get all locations within a certain radius of clusters of nodes in the job
 * @param {[string, object][]} nodeEntries - An array of node entries to check for overlapping work
 * @param {DirectoryData} directory - The location directory to search for locations
 * @param {number} searchRadiusInMeters - The search radius in meters
 * @returns {Promise<Object.<string, DirectoryLocationRecord>>} The locations within the search radiuses
 */
async function getLocationsNearNodesInJob(nodeEntries, directory, searchRadiusInMeters) {
  // Get the maps API
  const { maps: mapsAPI } = globalThis.google;
  if (mapsAPI == null) throw new Error('Google Maps API must be available in the global scope');

  // Create clusters of radiuses to check around the job nodes. Limit each radius to 36 meters
  const nodeClusterBounds = [];
  let currentBounds = new mapsAPI.LatLngBounds();
  const boundsRadiusThreshold = 200;
  for (const [, node] of nodeEntries) {
    // Get a snapshot of the current bounds
    const previousBounds = new mapsAPI.LatLngBounds(currentBounds.getSouthWest(), currentBounds.getNorthEast());
    // Get the coordinate of the node
    const nodeLatLng = new mapsAPI.LatLng(node.latitude, node.longitude);
    // Extend the current bounds by the node's location
    currentBounds.extend(nodeLatLng);
    // Get the updated radius of the bounds
    const boundsRadius = mapsAPI.geometry.spherical.computeDistanceBetween(currentBounds.getNorthEast(), currentBounds.getCenter());
    // If we are beyond the radius threshold, commit the previous bounds and start a new one
    if (boundsRadius > boundsRadiusThreshold) {
      nodeClusterBounds.push(previousBounds);
      // Start a new bounds with the current node
      currentBounds = new mapsAPI.LatLngBounds(nodeLatLng);
    }
  }
  // Add the final bounds to the list
  nodeClusterBounds.push(currentBounds);

  // Get all locations within the search radius of each bound
  const searchRadiusInKm = Convert(searchRadiusInMeters, 'm', 'km');
  const boundResults = await Parallel.map(nodeClusterBounds, async (bounds) => {
    // Get the radius of the bounds in kilometers
    const center = bounds.getCenter();
    const radiusInKm = mapsAPI.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), center) / 1000;
    // Get the search radius in kilometers from the radius of the bounds plus a
    // possible override. If this results in 0, default to 10 meters.
    const queryRadiusInKm = radiusInKm + searchRadiusInKm || 0.01;

    return await LocationSearch(directory, center.toJSON(), queryRadiusInKm, firestoreDb);
  });

  /** @type {Object.<String, DirectoryLocationRecord>} */
  const mergedResults = {};
  boundResults.forEach((result) => Object.assign(mergedResults, result));
  return mergedResults;
}

/**
 * Filter location entries by the search radius around the given search point
 * @param {[string, DirectoryLocationRecord][]} locationEntries - The location entries to filter (from GeoFire)
 * @param {object} searchPoint - The point to search around (a Google Maps LatLng object)
 * @param {number} searchRadiusInMeters - The search radius in meters
 * @returns {[string, DirectoryLocationRecord][]} The filtered location entries
 */
function filterLocationEntriesBySearchRadius(locationEntries, searchPoint, searchRadiusInMeters) {
  const { maps: mapsAPI } = globalThis.google;
  if (mapsAPI == null) throw new Error('Google Maps API must be available in the global scope');

  return locationEntries.filter(([, location]) => {
    // Skip locations without coordinates and without jobs
    if (!location.l || !location.d?.j) return false;

    // Check if the location is within the search radius of the node
    const locationLatLng = new mapsAPI.LatLng({ lat: location.l[0], lng: location.l[1] });
    const distanceFromSearchPoint = mapsAPI.geometry.spherical.computeDistanceBetween(searchPoint, locationLatLng);
    return distanceFromSearchPoint <= searchRadiusInMeters;
  });
}

/**
 * Get the location entry with the given geohash from the location directory
 * @param {string} geohash - The geohash to search for
 * @param {string} ownerCompany - The owner company of the location directory
 * @param {string} directoryId - The id of the location directory
 * @returns {Promise<[string, DirectoryLocationRecord]|null>} The id and data of the location entry, or null if not found
 */
async function getLocationEntryFromGeohash(geohash, ownerCompany, directoryId) {
  // Get the location document with the given geohash
  const locationsCollection = firestoreDb.collection(`companies/${ownerCompany}/directories/${directoryId}/locations`);
  const queryResult = await locationsCollection.where('g', '==', geohash).get();
  if (queryResult.empty) return null;

  // Get the first document and return its id and data
  const document = queryResult.docs[0];
  return [document.id, /** @type {DirectoryLocationRecord} */ (document.data())];
}

/**
 * Get information about a job pertaining to overlapping work
 * @param {string} jobId - The id of the job
 * @param {OverlappingLocation[]} [overlappingLocations] - Overlapping locations to check for the job
 * @returns {Promise<OverlappingJobRecord>} Information about the job
 */
async function getJobInfo(jobId, overlappingLocations = []) {
  const job = KatapultJob.fromId(jobId, { noCache: true });
  const locationsWithJob = overlappingLocations.filter(({ overlappingJobId }) => overlappingJobId === jobId);

  // Try to get the job metadata
  const name = await job.getName().catch(() => undefined);
  const metadata = await job.getMetadata().catch(() => undefined);
  const isShared = name != null;
  return {
    id: jobId,
    isShared,
    locations: locationsWithJob,
    name,
    appInfo: isShared
      ? {
          appName: metadata.app_name,
          appNumber: metadata.app_number,
          appType: metadata.app_type,
          appStatus: metadata.app_status,
          dateSubmitted: metadata.date_submitted,
          dateInstalled: metadata.date_installed,
          dateCanceled: metadata.date_canceled
        }
      : undefined
  };
}

/**
 * Get information about an overlapping location
 * @param {object} searchInfo - An object containing information about the search
 * @param {string} searchInfo.searchNodeId - The id of the location in the search job (usually a node id)
 * @param {number[]} searchInfo.searchNodeLocation - The location of the search node
 * @param {string} searchInfo.searchJobId - The id of the job that is being searched for overlapping work
 * @param {string} searchInfo.searchMethod - The method used to find the overlapping location ('UNIQUE_ID' or 'LOCATION')
 * @param {object} overlappingData - An object containing information about the overlapping location
 * @param {string} overlappingData.overlappingJobId - The id of the job that contains the overlapping location
 * @param {[string, object]|null} overlappingData.locationEntry - The entry of the overlapping location in the location directory
 * @param {string} [overlappingData.uniqueId] - The unique id of the overlapping location (if search method is 'UNIQUE_ID')
 * @param {object} [overlappingData.attributeLocationData] - The data from the attribute location document in the location directory (if
 * search method is 'UNIQUE_ID')
 * @returns {OverlappingLocation} Information about the overlapping location
 */
function getLocationInfo(searchInfo, overlappingData) {
  // Extract the search and overlapping data
  const { searchNodeId, searchNodeLocation, searchJobId, searchMethod } = searchInfo;
  const { overlappingJobId, locationEntry, uniqueId, attributeLocationData } = overlappingData;

  // Get the components of the location entry and calculate the distance
  const [overlappingLocationId, locationData] = locationEntry ?? [null, null];
  const distanceInMeters = Convert(GeoFire.distance(searchNodeLocation, locationData.l), 'km', 'm');

  return {
    searchNodeId,
    overlappingLocationId,
    searchJobId: searchJobId,
    overlappingJobId,
    searchMethod,
    details: {
      locationData,
      distance: distanceInMeters,
      ...(searchMethod === 'UNIQUE_ID' && {
        uniqueId,
        attributeLocationData
      })
    }
  };
}

/**
 * Remove duplicate overlapping locations from the overlapping records
 * @param {OverlappingJobRecord[]} allOverlappingRecords - All overlapping records
 * @returns {OverlappingJobRecord[]} The overlapping records with duplicate locations removed
 */
function removeDuplicateOverlappingRecords(allOverlappingRecords) {
  return allOverlappingRecords.map((record) => {
    /** @type {Map<string, OverlappingLocation>} */
    const uniqueLocations = new Map();
    for (const location of record.locations) {
      const existingLocation = uniqueLocations.get(location.searchNodeId);
      // Always prefer unique id records over location records; among records with the same search method, prefer shorter distances
      if (
        !existingLocation ||
        (location.searchMethod === 'UNIQUE_ID' && existingLocation.searchMethod === 'LOCATION') ||
        (location.searchMethod === existingLocation.searchMethod && location.details.distance < existingLocation.details.distance)
      ) {
        uniqueLocations.set(location.searchNodeId, location);
      }
    }

    // Return the record with the unique locations
    return { ...record, locations: [...uniqueLocations.values()] };
  });
}

/**
 * Filter overlapping jobs and sort them by their queue position
 * @param {OverlappingJobRecord[]} overlappingJobs List of overlapping jobs
 * @returns {OverlappingJobRecord[]} Filtered and sorted list of overlapping jobs
 */
function filterAndSortOverlappingJobs(overlappingJobs) {
  const filteredOverlappingJobs = overlappingJobs.filter((job) => job.isShared);
  return filteredOverlappingJobs.sort((a, b) => {
    const aDateSubmitted = a.appInfo?.dateSubmitted ?? Infinity;
    const bDateSubmitted = b.appInfo?.dateSubmitted ?? Infinity;
    return aDateSubmitted - bDateSubmitted;
  });
}

import '@polymer/polymer/polymer-legacy.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { GeoFire } from 'geofire';
import { Path } from '../../modules/Path.js';
import { GeofireTools } from '../../modules/GeofireTools.js';
import { AddOverlappingNotes } from '../../modules/AddOverlappingNotes.js';
import { getPortalDirectoryId } from '../../modules/PortalLocationDirectory.js';
import { MLD } from '../../modules/MasterLocationDirectory.js';
import { KatapultDialog } from '../katapult-elements/katapult-dialog.js';

/* global firebase, FirebaseWorker, Polymer */
Polymer({
  _template: html``,

  is: 'seed-job',

  properties: {
    jobId: { type: String },
    jobMetadata: {
      type: Object,
      value: () => ({})
    },
    jobName: { type: String },
    modelDefaults: {
      type: Object,
      value: () => {
        return {};
      }
    },
    userGroup: { type: String },
    utilityCompany: { type: String },
    seedingInitialized: {
      type: Object,
      value: {}
    }
  },

  observers: ['metaUpdate(jobName, userGroup)'],

  metaUpdate: async function () {
    if (!this.jobId || this.jobMetadata == null || this.jobName == null || this.userGroup == null) return;

    if (
      this.utilityCompany != 'ppl_attachments' ||
      this.jobName.substring(0, 4) != 'APP_' ||
      this.jobMetadata.review_contractor !== this.userGroup
    ) {
      this.checkSeedableJobType();
      return;
    }

    // Custom logic for Katapult for PPL Attachments
    let preppedForPredesign = this.jobMetadata.prepped_for_predesign;

    if (preppedForPredesign === true) return;

    const update = {};
    let pplLayersAdded = false;
    let pplSavedViewAdded = false;
    let choppedStateRoadAdded = false;

    // get the info for the 4 PPL layers that need to be added
    let layers = await FirebaseWorker.ref(`utility_info/_list`)
      .once('value')
      .then((s) => {
        return s
          .val()
          .filter(
            (element) =>
              element.name == 'PPL Poles' ||
              element.name == 'PPL Primary Overhead' ||
              element.name == 'PPL Secondary Overhead' ||
              element.name == 'PPL Current Limiting Fuse Areas' ||
              element.name == 'PA State Roads'
          );
      });
    // add each layer to the job
    let data = {};
    let list = {};
    for (const layerKey in layers) {
      const layer = layers[layerKey];
      let objectName = layer.url.replace(/\//g, '--');
      data[objectName] = {
        name: layer.name,
        type: 'Reference Layer'
      };
      list[objectName] = layer;
      list[objectName].ref_layer_config_source = `utility_info/_list/${layerKey}`;
      list[objectName].type = 'Reference Layer';
    }

    update['layers/data'] = data;
    update['layers/list'] = list;
    pplLayersAdded = true;
    // add update for default ppl saved view
    let savedViewUpdate = {
      default: true,
      name: 'PPL',
      settings: {
        mapBase: 'hybrid',
        multiJobIds: {
          [this.jobId]: {
            url: `photoheight/jobs/${this.jobId}/geohash`
          },
          '__refppl--state_roads': {
            database: 'ppl-kws-pa-state-roads',
            maxRadius: 1000,
            url: 'utility_info/ppl/state_roads',
            zIndex: 1
          }
        },
        showSpanDistances: true
      }
    };
    update['saved_views/default_ppl_saved_view'] = savedViewUpdate;
    pplSavedViewAdded = true;

    let nodes = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes`)
      .once('value')
      .then((s) => s.val());
    if (nodes) {
      // chop state roads so that it can be used on mobile
      let bounds = new google.maps.LatLngBounds();
      // size the LatLngBounds to the size of the job
      for (let nodeId in nodes) {
        if (nodes[nodeId].latitude && nodes[nodeId].longitude) {
          let latLng = new google.maps.LatLng(nodes[nodeId].latitude, nodes[nodeId].longitude);
          let circle = new google.maps.Circle({ center: latLng, radius: 20 });
          bounds.union(circle.getBounds());
        }
      }
      let center = bounds.getCenter();
      let radius = google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000;
      let geofire = new GeoFire(
        firebase.app().database('https://ppl-kws-pa-state-roads.firebaseio.com').ref('utility_info/ppl/state_roads')
      );
      // grab all of the pa state roads lines that fall within the LatLngBounds
      let lines = await geofire.once({ radius: radius, center: [center.lat(), center.lng()] });

      // loop through lines and convert to geojson
      let geojson = {};
      geojson.name = 'PA State Roads';
      geojson.type = 'FeatureCollection';
      geojson.features = [];
      let includedKeys = [];
      for (let key in lines) {
        if (!includedKeys.includes(key.split('~')[0])) {
          let feature = {};
          // set the type of the feature
          feature.type = 'Feature';
          // set the properties for the feature
          feature.properties = lines[key].info;
          feature.properties.stroke = lines[key].c;
          feature.properties['stroke-width'] = lines[key].w;
          feature.properties['stroke-opacity'] = lines[key].o;
          // set the geometry for the feature
          feature.geometry = {};
          feature.geometry.type = 'LineString';
          feature.geometry.coordinates = [];
          let point1 = [lines[key].l[1], lines[key].l[0]];
          let point2 = [lines[key].l2[1], lines[key].l2[0]];
          feature.geometry.coordinates.push(point1);
          feature.geometry.coordinates.push(point2);
          geojson.features.push(feature);
          includedKeys.push(key.split('~')[0]);
        }
      }
      choppedStateRoadAdded = await this.storeStateRoads(geojson);
    }

    if (pplLayersAdded && pplSavedViewAdded && choppedStateRoadAdded) {
      update['metadata/prepped_for_predesign'] = true;
    } else {
      update['metadata/prepped_for_predesign'] = false;
    }
    if (config.firebaseData.utilityCompany == 'ppl_attachments') {
      // Get the Portal Location Directory
      const portalDirectoryId = await getPortalDirectoryId(this.userGroup, config.firebaseData.utilityCompany);
      const portalDirectory = (await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true }))[portalDirectoryId];
      await AddOverlappingNotes(this.jobId, [portalDirectory], {
        useUniqueIds: true,
        update,
        nodes,
        userGroup: this.userGroup,
        jobStyles: this.domHost.jobStyles
      });
      for (let nodeId in nodes) {
        // set the done attribute to false (if it isn't already)
        const done = Path.get(nodes[nodeId], 'attributes.done.*');
        if (done == null) {
          update[`nodes/${nodeId}/attributes/done`] = { app_added: false };
          Path.set(nodes[nodeId], 'attributes.done', update[`nodes/${nodeId}/attributes/done`]);
          GeofireTools.setGeohash('nodes', nodes[nodeId], nodeId, this.domHost.jobStyles, update);
        }
      }
    }
    FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`)
      .update(update)
      .then(() => {
        this.fire('toast', { message: 'Seeding job done' });
      })
      .catch((error) => {
        console.error(error);
        KatapultDialog.alert({
          body: 'There was an error seeding the job. Please contact Katapult Pro Support at support@katapultengineering.com.',
          dialog: {
            color: 'var(--paper-red-500)',
            icon: 'error',
            title: 'Seeding Job Failed'
          }
        });
      });
  },

  checkSeedableJobType: function () {
    //This object has aliases to tell us where to get the data based upon the company and the job text.
    //For example, a standard DCS job will map to PPL via katapult.app_; a relocations job gets there via katapult.rel_
    if (this.seedingInitialized[this.jobId] == null) {
      this.seedingInitialized[this.jobId] = true;
      var jobNameAliases = {
        katapult: {
          app_: 'ppl',
          rel_: 'ppl'
        }
      };
      var cleanJobName = this.jobName.toLowerCase();
      //Check that the company even has any seeding job names:
      if (jobNameAliases[this.userGroup]) {
        //check if any of the prefixes match to the
        for (var jobNameAlias in jobNameAliases[this.userGroup]) {
          if (cleanJobName.indexOf(jobNameAlias) != -1) {
            this.seedSource = jobNameAliases[this.userGroup][jobNameAlias];
            this.jobNumber = cleanJobName.replace(jobNameAlias, '').trim();
            this.checkJobNodesExist(this.seedSource);
            break;
          }
        }
      }
    }
  },

  checkJobNodesExist: function () {
    //checks that the job is a New Job
    FirebaseWorker.ref('photoheight/jobs/' + this.jobId + '/nodes').once(
      'value',
      function (snapshot) {
        if (snapshot.val() == null) {
          this.fire('progress', { message: 'Seeding poles for ' + this.jobName, duration: 0 });
          this.getSeedData();
        }
      }.bind(this)
    );
  },

  getSeedData: function () {
    switch (this.seedSource) {
      case 'ppl':
        var key = FirebaseWorker.ref('photoheight/server_requests/context_layers/requests/').push({
          search: this.jobNumber,
          overlapping: true,
          userGroup: this.userGroup
        }).key;
        FirebaseWorker.ref('photoheight/server_requests/context_layers/responses/' + key).on(
          'value',
          function (snapshot) {
            var contextData = snapshot.val();
            if (contextData != null && contextData.poles != null) {
              this.generateSeedPoles(contextData.poles.features, contextData.overlapping);
              this.storeStateRoads(contextData.state_roads);
              snapshot.ref.remove();
            }
          }.bind(this)
        );
    }
  },

  storeStateRoads: function (stateRoads) {
    stateRoads = stateRoads || {};
    let name = 'App PA State Roads' + (stateRoads.features.length != 0 ? '' : ' (Empty)');
    stateRoads.name = name;
    let update = {};
    update['list/app_pa_state_roads'] = { name: name };
    update['data/app_pa_state_roads'] = stateRoads;
    let result = FirebaseWorker.ref('photoheight/jobs/' + this.jobId + '/layers')
      .update(
        update,
        function (err) {
          if (err) {
            this.fire('progress', { message: err, duration: 10000 });
          }
        }.bind(this)
      )
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
    return result;
  },

  generateSeedPoles: function (features, overlapping) {
    var ref = FirebaseWorker.ref('photoheight');
    var update = {};
    switch (this.seedSource) {
      case 'ppl':
        var companyLookup = {
          'CENTURY LINK': 'centurylink',
          VERIZON: 'verizon',
          FRONTIER: 'frontier',
          WINDSTREAM: 'windstream'
        };
        Object.keys(features).forEach(
          function (key) {
            if (features[key].properties != null && features[key].properties.app_pole_order != null) {
              var node = {};
              node.latitude = features[key].geometry.coordinates[1];
              node.longitude = features[key].geometry.coordinates[0];
              node.pole_order = features[key].properties.app_pole_order;
              node.attributes = {};

              if (
                features[key].properties.attachment_types &&
                features[key].properties.attachment_types.toLowerCase().search('wireless antenna') != -1
              ) {
                node.attributes.hydrant_distance = { seed_data: '' };
              }

              if (features[key].properties.id_no != null) {
                var pid = ref.push().key;
                var pole_tag = {
                  company: 'PPL',
                  owner: features[key].properties.pole_owner == 'PPL',
                  tagtext: features[key].properties.id_no
                };
                node.attributes.pole_tag = {};
                node.attributes.pole_tag[pid] = pole_tag;

                if (overlapping) {
                  overlapping.forEach((x) => {
                    if (x.ppl_grid_no === pole_tag.tagtext) {
                      if (!node.attributes.overlapping_note) node.attributes.overlapping_note = {};
                      node.attributes.overlapping_note[ref.push().key] = x.overlapping_note;
                    }
                  });
                }
              }
              var altTagFound = false;
              if (features[key].properties.pole_owner != 'PPL') {
                if (features[key].properties.pole_owner_name != null) {
                  for (var company in companyLookup) {
                    if (features[key].properties.pole_owner_name.replace(/\s/g, '').toLowerCase().indexOf(companyLookup[company]) != -1) {
                      altTagFound = true;
                      var altpid = ref.push().key;
                      var alt_tag = {
                        company: this.domHost.getFormatForCompanyName(company) || company,
                        owner: true,
                        tagtext: ''
                      };
                      node.attributes.pole_tag[altpid] = alt_tag;
                      break;
                    }
                  }
                  if (altTagFound == false) {
                    var notePid = ref.push().key;
                    var note = 'PPL DB Owner: ' + features[key].properties.pole_owner;
                    node.attributes.internal_note = {};
                    node.attributes.internal_note[notePid] = note;
                  }
                } else {
                  var notePid = ref.push().key;
                  var note = 'PPL DB Owner: None';
                  node.attributes.internal_note = {};
                  node.attributes.internal_note[notePid] = note;
                }
                // Only flag_for_review if we actually have confirmation that it is not PPL owned (vs. say a typo)
                if (features[key].properties.pole_owner != null) {
                  node.attributes.foreign_owned = { value: true };
                }
              } else {
                if (features[key].properties.attachment_types) {
                  var attTypeNotePid = ref.push().key;
                  if (node.attributes.internal_note == null) {
                    node.attributes.internal_note = {};
                  }
                  node.attributes.internal_note[attTypeNotePid] =
                    features[key].properties.activity_types + ': ' + features[key].properties.attachment_types;
                }
              }
              const nodeType = {
                seed_data: 'pole'
              };
              node.attributes[this.modelDefaults.node_type_attribute] = nodeType;
              var donePid = ref.push().key;
              node.attributes.done = {};
              node.attributes.done[donePid] = false;
              node.button = 'aerial';
              var nodeId = ref.push().key;
              GeofireTools.setGeohash('nodes', node, nodeId, this.domHost.jobStyles, update);
              update['/nodes/' + nodeId] = node;
            }
          }.bind(this)
        );
    }
    ref.child('/jobs/' + this.jobId).update(
      update,
      function (err) {
        if (err) {
          console.warn('seedJob err', err);
          this.fire('progress', { message: 'Seed poles failed:' + err, duration: 10000 });
        } else {
          this.fire('progress', { message: 'Seed poles completed', duration: 10000 });
        }
      }.bind(this)
    );
  }
});
